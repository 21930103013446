<i18n>
	{
		"en": {
			"changePass": "Change Password",
			"enterNewPass": "Enter New Password",
			"reEnter": "Re-Enter New Re-Password",
			"reset": "Reset",
			"save": "Save",
			"verificationSent": "We have sent verification code on",
			"enterVerificationCode": "Please enter verification code",
			"submitCode": "Submit Code",
			"enterNumber": "Enter Mobile Number",
			"getCode": "Get Code"
		}
	}
</i18n>
<template>
	<div class="hero-banner">
		<div class="hero-banner-content">
			<div class="container">
				<div class="login-modal login-box">
					<div class="modal-header">
						<h6>
							<b>{{ $t("changePass") }}</b>
						</h6>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-sm-12 col-md-8">
								<transition name="fade" mode="out-in">
									<div :key="1" v-if="numberVerified">
										<form @submit.prevent="changePassword">
											<div class="form-row">
												<div class="form-group col-sm-6">
													<label>{{ $t("enterNewPass") }}</label>
													<input
														type="password"
														name="new_password"
														maxlength="255"
														minlength="6"
														class="form-control"
														value
													/>
													<div class="help-box" v-if="validationError">
														<span class="text-danger">{{validationError}}</span>
													</div>
												</div>
												<div class="form-group col-sm-6">
													<label>Re-Enter New Re-Password</label>
													<input
														type="password"
														name="new_re_password"
														maxlength="255"
														minlength="6"
														class="form-control"
														value
													/>
													<div class="help-box" v-if="validationError">
														<span class="text-danger">{{validationError}}</span>
													</div>
												</div>
											</div>
											<div class="form-group">
												<button type="reset" class="btn btn-lg btn-danger">Reset</button>
												<button type="submit" class="btn btn-lg btn-success">Save</button>
											</div>
										</form>
									</div>
									<div :key="2" v-else-if="verificationSent">
										<form @submit.prevent="submitVerificationCode">
											<div class="form-group">
												<div class="text-muted">{{ $t("verificationSent") }}&nbsp; {{phoneNumber}}</div>
											</div>
											<div class="form-group">
												<label>{{$t("enterVerificationCode")}}</label>
												<input
													v-model="verificationCode"
													class="form-control"
													type="tel"
													pattern="[0-9]+"
													placeholder="123456"
													maxlength="6"
												/>
											</div>
											<button type="submit" class="btn btn-danger mt-2">{{ $t("submitCode") }}</button>
										</form>
									</div>
									<div :key="3" v-else>
										<form @submit.prevent="getVerificationCode">
											<div class="form-group">
												<label>Enter Mobile Number</label>
												<div class="input-group">
													<div class="input-group-prepend">
														<span class="input-group-text" id="basic-addon1">+977</span>
													</div>
													<input
														type="tel"
														pattern="[0-9]+"
														placeholder="9876543210"
														minlength="10"
														maxlength="10"
														v-model="phoneNumber"
														class="form-control"
														autofocus
														required
													/>
												</div>
											</div>
											<button type="submit" class="btn btn-danger mt-4">Get Code</button>
										</form>
									</div>
								</transition>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "change-password",
	components: {  },
	computed: {
		currentUser() {
			return {
				name: "Suman Khadka"
			};
		}
	},
	methods: {
		changePassword() {},
		getVerificationCode() {
			this.verificationSent = true;
		}
	},
	data: () => ({
		phoneNumber: "",
		numberVerified: false,
		verificationSent: false,
		verificationCode: ""
	})
};
</script>
